@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
  background-color: #403d7e;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.around {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: blue;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
  transform-origin: 0 -300px; /* Ajuste a distância do centro conforme necessário */
}

.around:nth-child(2) { transform: rotate(0deg) translateX(-50%); }
.around:nth-child(3) { transform: rotate(40deg) translateX(-50%); }
.around:nth-child(4) { transform: rotate(80deg) translateX(-50%); }
.around:nth-child(5) { transform: rotate(120deg) translateX(-50%); }
.around:nth-child(6) { transform: rotate(160deg) translateX(-50%); }
.around:nth-child(7) { transform: rotate(200deg) translateX(-50%); }
.around:nth-child(8) { transform: rotate(240deg) translateX(-50%); }
.around:nth-child(9) { transform: rotate(280deg) translateX(-50%); }
.around:nth-child(10) { transform: rotate(320deg) translateX(-50%); }

.usage-map{
  height:95vh !important;
  width:auto !important;
}